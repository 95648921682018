import React from "react"
import Layout from "../components/layout"
import RequesterLanding from "./requesterLanding/requesterlanding"
import WorkerLanding from "./workerlanding";
import './app.scss';
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8142442.js"></script>
      </Helmet>
      <Layout>

        {
          process.env.GATSBY_APP_USER_TYPE === "requester" ? <RequesterLanding /> : <WorkerLanding />
        }
      </Layout>
    </>
  )
}

export default IndexPage
