import React, { useState } from 'react';
import { Link } from 'gatsby'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import SEO from '../../components/seo'
import useFirestore from '../../modules/flamelink-module';
import _ from 'lodash';
import './workerlanding_styles.scss'

import homebanner from '../../images/landing/workforce.jpg';
import bgcircle from '../../images/landing/bgcircle.svg';
import transparency from '../../images/landing/transparency.svg';
import quality from '../../images/landing/quality.svg';
import unbiaseddata from '../../images/landing/unbiased-data.svg';
import satisfaction from '../../images/landing/satisfaction.svg';
import money from '../../images/landing/money.svg';
import workerHomeBanner from '../../images/landing/workerHomeBanner.png';
import appstore from '../../images/appstore.png';
import playstore from '../../images/playstore.png';
import annotImg from '../../images/landing/annot-img.png';
import annotRays from '../../images/landing/annot-rays.png';
import annotTxt from '../../images/landing/annot-txt.png';
import {
    WORKFORCE_PLAY_STORE,
    WORKFORCE_APP_STORE
} from '../../config/constants';
import ContactModal from '../../components/ContactModal';

export default function WorkerLanding({ props }) {
    const docs = useFirestore('fl_content', 'workerHome');
    const useCasesData = useFirestore('fl_content', 'usecase');
    const useCases = useCasesData[0];
    const [openContact, setOpenContact] = useState(false);

    const handleOpen = () => {
        setOpenContact(true);
    };

    const handleClose = () => {
        setOpenContact(false);
    };

    return (
        <div className="home-cont workerHome">
            <SEO title="Unbiased Workforce" description="Unbiased happening" />
            <ContactModal open={openContact} handleClose={handleClose} />
            <section className="workerHomeBanner">
                <img src={homebanner} alt="Home Banner" className="homeBannerImg" />
                <div className="banner-overlay">
                    <div className="bannerCont">
                        <h1>MAKING A SOCIAL IMPACT</h1>
                        <p>{!_.isEmpty(docs) && docs[0].socialImpactText}</p>

                        <Link href={process.env.GATSBY_APP_LOGIN_URL} className="">
                            <Button className="btn-pink">Register as a worker</Button>
                        </Link>
                    </div>
                </div>
            </section>

            <section className="makeMoney">
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6}>
                            <h2>MAKE MONEY IN YOUR <br />SPARE TIME</h2>
                            <p>Want to make money in your spare time? Become an Unbiased Worker and start applying your skills to the thousands of available tasks</p>
                            <div className="btLink">
                                <Button href={process.env.GATSBY_APP_LOGIN_URL} >REGISTER and Start Earning today</Button>
                            </div>
                            <ul>
                                <li><Link to={WORKFORCE_PLAY_STORE} target="_blank" rel="noreferrer"><img src={appstore} alt="App Store" /></Link></li>
                                <li><Link to={WORKFORCE_APP_STORE} target="_blank" rel="noreferrer"><img src={playstore} alt="Play Store" /></Link></li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <img src={workerHomeBanner} className="bannerImg" alt="Banner" />
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <section className="workerGrids">
                <Container>
                    <div className="gBox">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} className="contBox">
                                <h5>{!_.isEmpty(docs) && docs[0].typeOfWorkHeading}</h5>
                                <h2>Ground truth <br />Data Collection</h2>
                                <p>{!_.isEmpty(docs) && docs[0].dataAnnotationText}</p>
                                <div className="btLink">
                                    <Button href={process.env.GATSBY_APP_LOGIN_URL} >GET STARTED</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="borderedBox">
                                <div className="bBInner">
                                    <div className="annotBox">
                                        <div className="circle">
                                            <img src={annotImg} alt="Icon" width="30" />
                                        </div>
                                        <p>{!_.isEmpty(docs) && docs[0].taggingText}</p>
                                    </div>
                                    <div className="annotBox">
                                        <div className="circle">
                                            <img src={annotTxt} alt="Icon" width="30" />
                                        </div>
                                        <p>{!_.isEmpty(docs) && docs[0].taggingText}</p>
                                    </div>
                                    <div className="annotBox">
                                        <div className="circle">
                                            <img src={annotRays} alt="Icon" width="30" />
                                        </div>
                                        <p>{!_.isEmpty(docs) && docs[0].taggingText}</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="gBox">
                        <Grid container>

                            <Grid item xs={12} sm={12} md={6} className="borderedBox">
                                <div className="bBInner">
                                    <div className="annotBox">
                                        <div className="circle">
                                            <img src={annotImg} alt="Icon" width="30" />
                                        </div>
                                        <p>{!_.isEmpty(docs) && docs[0].taggingText}</p>
                                    </div>
                                    <div className="annotBox">
                                        <div className="circle">
                                            <img src={annotTxt} alt="Icon" width="30" />
                                        </div>
                                        <p>{!_.isEmpty(docs) && docs[0].taggingText}</p>
                                    </div>
                                    <div className="annotBox">
                                        <div className="circle">
                                            <img src={annotRays} alt="Icon" width="30" />
                                        </div>
                                        <p>{!_.isEmpty(docs) && docs[0].taggingText}</p>
                                    </div>
                                    <div className="annotBox">
                                        <div className="circle">
                                            <img src={annotRays} alt="Icon" width="30" />
                                        </div>
                                        <p>{!_.isEmpty(docs) && docs[0].taggingText}</p>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} className="contBox">
                                <h5>{!_.isEmpty(docs) && docs[0].typeOfWorkHeading}</h5>
                                <h2>DATA ANNOTATION KEEPING <br />HUMAN IN THE LOOP</h2>
                                <p>{!_.isEmpty(docs) && docs[0].dataAnnotationText}</p>
                                <div className="btLink">
                                    <Button href={process.env.GATSBY_APP_LOGIN_URL}>GET STARTED</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>

            <section className="whyChoose">
                <img src={bgcircle} alt="Background" className="bgCircle" />
                <section container className="why-choose-cont">
                    <h2>CODE OF ETHICS</h2>
                    <p>{!_.isEmpty(docs) && docs[0].dataAnnotationText}</p>

                    <div className="circleContLarge">
                        <div className="cCont">
                            <div className="circle">
                                <img src={transparency} alt="Transparency" width="36" />
                            </div>
                            <h6>TRANSPARENCY</h6>
                        </div>
                        <div className="cCont">
                            <div className="circle">
                                <img src={quality} alt="Quality Assurance" width="36" />
                            </div>
                            <h6>QUALITY ASSURANCE</h6>
                        </div>
                        <div className="cCont">
                            <div className="circle">
                                <img src={unbiaseddata} alt="Unbiased Data" width="34" />
                            </div>
                            <h6>UNBIASED DATA</h6>
                        </div>
                    </div>

                    <div className="circleContSmall">
                        <div className="cCont">
                            <div className="circle">
                                <img src={satisfaction} alt="Worker Satisfaction" width="36" />
                            </div>
                            <h6>WORKER SATISFACTION</h6>
                        </div>
                        <div className="cCont">
                            <div className="circle">
                                <img src={money} alt="Spam Free Accounts" width="36" />
                            </div>
                            <h6>SPAM FREE ACCOUNTS</h6>
                        </div>
                    </div>


                </section>
            </section>


            <section className="leverageData">
                <h3>Are you a Requestor and want to leverage data?</h3>
                <Button onClick={handleOpen}>CONTACT US</Button>
            </section>

            <section className="useCases">
                <h2>TOP USE CASES</h2>
                <Container>
                    <Grid container>
                        {
                            !_.isEmpty(useCases) && useCases.hasOwnProperty('useCases') &&
                            useCases.useCases[0].topUseCases.map((val, index) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <div className="useCaseGrid">
                                            <img src={val.location} alt={val.title ? val.title : 'UseCases'} />
                                            <h4>{val.title}</h4>
                                            <p>{val.summary}</p>
                                            <Link to="/#" rel="noreferrer">LEARN MORE</Link>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Container>
            </section>
        </div>

    );
}
